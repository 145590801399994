.ui.main.grid {
  left: 15rem;
  position: relative;
  width: calc(100% - 15rem);
  margin: 0;
  padding: 1rem !important;
}

.ui.menu > .grid, .ui.menu > .container {
  margin: none;
  width: 100%;
}

.ui.fixed.inverted.main.menu {
  display: none;
  padding: 0;

  .item:first-child {
    text-align: center;
    i {
      margin: 0;
    }
  }

  .header.item {
    .image {
      margin-right: 12px;
    }
  }

}

.ui.left.fixed.vertical.inverted.menu {
  padding-left: 0;
  padding-right: 0;
  overflow-y: auto;
}

.ui.left.fixed.vertical.inverted.menu > .item:first-child {
  padding-bottom: 40px;
  padding-top: 20px;
  .flexbox {
    display: flex;
    align-content: center;
    align-items: center;

    span {
      margin-left: 12px;
      font-weight: bold;
    }
  }
  a {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
}

/* SMARTPHONE */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .ui.main.grid .row {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ui.left.fixed.vertical.inverted.menu {
    display: none;
  }

  .ui.fixed.inverted.main.menu {
    display: block;
  }

  .ui.main.grid {
    left: 0%;
    padding-top: calc(1rem + 44px) !important;
    position: relative;
    width: 100%;
  }
}

/* TABLET */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  .ui.left.fixed.vertical.inverted.menu {
    display: none;
  }

  .ui.fixed.inverted.main.menu {
    display: block;
  }

  .ui.main.grid {
    left: 0%;
    padding-top: calc(1rem + 44px) !important;
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .ui.fixed.inverted.main.menu {
    display: none;
  }

  .ui.left.fixed.vertical.inverted.menu {
    display: block;
  }
}