.newcourierForm{
  .rightBtn {
    float: right;
  }
  .locationColumn {
    padding-left: 0 !important;padding-right: 0!important;
  }
  .addLocationBtn{
    display: inline-block;
  }
  .noteClass {
    font-size:11px;
  }
  .validation{
      margin-top: 2px;
        color: #fd5b74;
            -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
              -moz-animation: fadein 0.5s; /* Firefox < 16 */
                -ms-animation: fadein 0.5s; /* Internet Explorer */
                -o-animation: fadein 0.5s; /* Opera < 12.1 */
                    animation: fadein 0.5s;
    }
    hr {
      border : 1px solid rgba(34, 36, 38, 0.15)
    }
    input[readonly="readonly"] {
      pointer-events: none;
      opacity: 0.45;
    }
}