@import "colours";
@import "pods";

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: $hubbedLightGrey;
}

#full-page-loader {
  display: none;
  &.show {
    display: block;
  }
}

.ember-notify-default.ember-notify-cn {
  bottom: 20px;
  right: 20px;
}

.ui.form.clearing:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

.standard-page {
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  background: $hubbedLightGrey;

  h1.hubbed-page-heading {
    margin-top: 0;
  }

  .ui.pagination.menu {
    float: right;
  }

  .header-with-search{
    h1.hubbed-page-heading {
      float:left;
    }

    .search-wrapper {
      float:left;
      width: 35vw;
      margin-left: 20px;
    } 
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}

 .leaflet-container {
    height: 500px;
    width: 100%;
  }

.unauthenticated-main-body {
  width: 100vw;
  height: 100vh;
}

.checkbox-padding {
  padding: 10px 0;
}

.row-matching-search {
  background-color: #f0fff0
}

.ember-select-guru__container {
  position: relative !important;
}

.validation{
  margin-top: 2px;
    color: #fd5b74;
        -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 0.5s; /* Firefox < 16 */
            -ms-animation: fadein 0.5s; /* Internet Explorer */
            -o-animation: fadein 0.5s; /* Opera < 12.1 */
                animation: fadein 0.5s;
}

#h1Span {
  font-size: 1.5rem;
}
.tdWrdBreak{
  word-break: break-all;
  max-width: 250px;
}
.justify-content-between {
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.bottom-link{
  font-style: italic;
}
.ui.message p {
  word-break: break-all !important;
}
body .ui.grid .ui.left.fixed.vertical.inverted.menu .flexbox span.logoTag{
    word-break: break-all !important; margin-left: auto; margin-right: auto;
}
.wordBreak {
  word-break: break-all;
}
.ui.left.fixed.vertical.inverted.menu > .item:first-child {
  padding-bottom: 20px !important;
}
.downloadReport {
  margin-top: 20px!important; height: 35px;
}
.dashboardLabels {
  text-align : center;
}
.dashboardLabelFont {
  text-align : center;
  font-size: 2rem;
}  
.mainLogo { 
  max-height: 100%;margin: 0 auto;
}
.topMainLogo {
  height: 80px;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-dismissible {
  .closex {
    text-decoration: none !important;
    font-size: 18px !important;
    position: absolute;
    top: 12px;
    right: 10px;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }
}
.serach-button{
  button{
    margin-top: 17px !important;
  }
}
/* Create three equal columns that floats next to each other */
.track-column {
  float: left;
  width: 33.33%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.radio {
  // ...existing styles

  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}