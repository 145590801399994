.login-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 300px;

    background: black;

    .logo{
        width: 70%;
        height: 70%;
        margin-left:12%;
    }

    .reset-password{
        color:black;
        float:right;          
        font-size: small;      
    }

    .full-width
    {
        width:100%;
    }

    .black-button
    {
        background-color: black;
    }

    .form-wrapper {
        width: 400px;
        .segment {
            padding:50px;
            //background: rgba(white, 0.9);
            .ui.header {
                text-align: center;
            }
        }
    }
}
